import { useEffect, useState } from 'react';
import './App.css';

function App() {
  const [profileData, setProfileData] = useState(null);
  const [plans, setPlans] = useState([]);
  const [token, setToken] = useState(null);
  const [unauthorised,setUnauthorised]=useState(false)

  useEffect(() => {
    let path=window.location.pathname
    const custome_token = path.replace("/","")
    console.log(custome_token);
    

    // Validating the custom token
    fetch('https://api.nearbyjobs.in/api/auth/validate-custom-token', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        token: custome_token,
      }),
    })
      .then(response => response.json())
      .then(data => {
        if (data.token) {
          getProfile(data.token);
        }else{
          setUnauthorised(true)
        }
      });
  }, []);

  const getProfile = (_token) => {
    setToken(_token)
    fetch('https://api.nearbyjobs.in/api/auth/profile-get/', {
      headers: {
        'Authorization': _token,
      },
    })
      .then(response => response.json())
      .then(data => { 
        if(data){
          setProfileData(data);
          if (data.role === 'user') {
            getUserPlan(_token);
          } else {
            getBusinessPlan(_token);
          }
        }else{
          setUnauthorised(true)
        }
      });
  };

  const getBusinessPlan = (_token) => {
    fetch('https://api.nearbyjobs.in/api/business/plans', {
      headers: {
        'Authorization': _token,
      },
    })
      .then(response => response.json())
      .then(data => setPlans(data));
  };

  const getUserPlan = (_token) => {
    fetch('https://api.nearbyjobs.in/api/user/plans', {
      headers: {
        'Authorization': _token,
      },
    })
      .then(response => response.json())
      .then(data => setPlans(data));
  };

  const handleSubscription = (id) => {
    if (profileData?.role === 'user') {
      handleUserSubscribe(id);
    } else {
      handleBusinessSubscribe(id);
    }
  };

  const handleUserSubscribe = (id) => {
    fetch(`https://api.nearbyjobs.in/api/user/subscribe-plan/${id}`, {
      headers: {
        'Authorization': token,
      },
    })
      .then(response => response.json())
      .then(data => toPayment(data.url));
  };

  const handleBusinessSubscribe = (id) => {
    fetch(`https://api.nearbyjobs.in/api/business/subscribe-plan`, {
      headers: {
        'Authorization': token,
      },
    })
      .then(response => response.json())
      .then(data => toPayment(data.url));
  };

  const toPayment = (url) => {
    setToken("")
    if (url) {
      window.location.href = url;
    }else{
      setUnauthorised(true)
    }
  }

  return (
    <div className="app">
      <div className="mt-3 flex flex-col md:flex-row justify-center md:justify-between align-center w-full">
        <h1 className="md:pl-20 text-center">NearByJobs</h1>
        <div className="flex align-center gap-2 justify-center md:pr-20">
          <p className="md:pt-2">info@nearbyjobs.in</p>
        </div>
      </div>
      <div>
        {unauthorised?<p className="title text-center"> ⚠️ Unauthorized</p>:<p className="title text-center">Payment Plans</p>}
        <div className="flex flex-col md:flex-row justify-around">
          {plans.map((plan) => (
            <div className="card" key={plan._id}>
              <div className="header">
                <p>{plan.name}</p>
              </div>
              <div className="body">
                <p className="price">₹{plan.price}</p>
                <p>+ 18% GST</p>
                <p className="duration mb-4 mt-4">Duration: <span>{plan.duration} </span> {plan.plan_type}</p>
                <button onClick={() => handleSubscription(plan._id)}>Checkout</button>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="flex flex-col md:flex-row align-center justify-evenly w-full text-center" style={{ backgroundColor: 'rgb(226, 228, 230)', padding: '40px 0' }}>
        <a href="mailto:info@nearbyjobs.in">
          <span style={{ color: '#828282' }}>Contact Us:</span> info@nearbyjobs.in
        </a>
        <a href="https://www.nearbyjobs.in/privacy-policy" target="_blank" rel="noopener noreferrer">
          Privacy policy
        </a>
        <a href="https://www.nearbyjobs.in/terms-and-conditions" target="_blank" rel="noopener noreferrer">
          Terms & Conditions
        </a>
        <a href="https://www.nearbyjobs.in/refund-policy" target="_blank" rel="noopener noreferrer">
          Refund Policy
        </a>
      </div>
    </div>
  );
}

export default App;
